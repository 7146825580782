import axiosInstance from '@/plugins/axios'
import { toSelectOptions } from '@/utils/antd'
export const getProjectList = () => {
  return axiosInstance.post('/attendance/web/project/all').then((res) => {
    return res.data.map((item) => {
      return {
        value: item.projectCode,
        label: item.projectName,
        origin: item,
      }
    })
  })
}
export const getLeaderList = (projectCode) => {
  return axiosInstance
    .post('/attendance/web/leader/all', {
      projectCode,
    })
    .then((res) => {
      return res.data.map((item) => {
        return {
          value: item.leaderId,
          label: `${item.leaderName}${item.leaderCardId}`,
          origin: item,
        }
      })
    })
}
export const getProjectListByCard = ({ cardId = '' } = {}) => {
  return axiosInstance
    .post(`/report/evidence/chain/getProjectList`, {
      cardId,
    })
    .then((res) => {
      return res.data.map((item) => {
        return {
          value: item.projectCode,
          label: item.projectName,
          origin: item,
        }
      })
    })
}
export const getLeaderListByCard = ({ cardId = '', projectCode = '' } = {}) => {
  return axiosInstance
    .post(`/report/evidence/chain/getLeaderList`, {
      cardId,
      projectCode,
    })
    .then((res) => {
      return toSelectOptions(res.data, { labelKey: 'leaderName', valueKey: 'leaderId' })
    })
}
export const getWorkerTypeList = () => {
  return axiosInstance.post('/report/evidence/chain/getWorkerType').then((res) => {
    return res.data.map((item) => {
      return {
        value: item,
        label: item,
        origin: item,
      }
    })
  })
}
export const getWorkerList = (projectCode, leaderId = '') => {
  return axiosInstance
    .post('/attendance/web/worker/all', {
      projectCode,
      leaderId,
    })
    .then((res) => {
      return res.data.map((item) => {
        return {
          value: item.leaderId,
          label: `${item.leaderName}${item.leaderCardId}`,
          origin: item,
        }
      })
    })
}
