<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="modal.form" v-bind="modalFormLayout">
      <a-form-item label="项目">
        <a-select
          class="form-control lg"
          v-decorator="['projectCode', { rules: [{ required: true, message: '请选择' }] }]"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          :filterOption="filterByPY"
        ></a-select>
      </a-form-item>
      <a-form-item label="身份证号">
        <a-input
          v-decorator="[
            'cardId',
            {
              rules: [{ required: true, message: '请输入身份证号码' }],
            },
          ]"
          placeholder="请输入"
        ></a-input>
      </a-form-item>
      <a-form-item label="日期">
        <a-range-picker v-decorator="['date']" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
import { getProjectList } from '@/service/getData'
export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '辅工加班考勤重算',
        data: {},
      },
      projectList: [],
    }
  },
  methods: {
    async start() {
      this.resetModal()
      this.projectList = await getProjectList()
      this.modal.visible = true

      return new Promise((resolve, reject) => {
        this.$once('end', function(state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = this.formatTimeRangeParams(values, 'date', {
            format: 'YYYY-MM-DD',
          })
          try {
            await this.$axios.post('/attendance/oa/recalculate/auxiliary/attendance', {
              ...params,
            })
            this.$emit('end', 'ok')
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>
